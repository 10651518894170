<template>
  <div
      class=""
      style="min-height: 50vh"
  >
    <table class="table card-table table-vcenter text-nowrap datatable">
      <thead class="">
      <!-- Table head -->
      <tr>
        <th
            v-for="(column, index) in showedFields"
            :key="index"
            scope="col"
            :class="column.class"
            class="dark:text-white/70"
            :style="column.style"
        >
          <slot :name="`header-column-${column.key}`">
            {{ column.label }}
          </slot>
        </th>
      </tr>
      <!-- End of table head -->

      <tr v-if="loading" class="p-0">
        <!-- Loading spinner -->
        <th :colspan="showedFields.length" class="!p-0">
          <div
              class="progress-bar ti-main-progress-bar bg-primary"
              role="progressbar"
              style="width: 25%; height: 5px"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
          ></div>
        </th>
        <!-- Loading spinner -->
      </tr>
      </thead>

      <tbody>
      <!-- Table body -->
      <tr v-for="(row, index) in data" :key="index" class="product-1">
        <td
            v-for="(field, index) in showedFields"
            :key="field.key + index"
            :style="field.style"
            :class="field.class"
        >
          <slot :name="`body-column-${field.key}`"
                :row="row"
                :field="field"
                :index="index">
            <component
                v-if="field.type && field.type in definedTableFields"
                :is="definedTableFields[field.type].component"
                :field="field"
                :row="row"
                :key="field.key + '-' + index"
                :value="row[field.key]"
            />
            <template v-else>
              {{ row[field.key] }}
            </template>
          </slot>
        </td>
      </tr>
      <!-- End of table body -->

      <!-- Total Table -->
      <tr v-if="hasTotals && data?.length" class="product-1 bg-secondary/10">
        <td
            v-for="(field, index) in showedFields"
            :key="field.key + index"
            :style="field.style"
            :class="field.class"
            class="!my-1 !py-1"
        >
          <template v-if="field.inTotals">
            <slot :name="`body-column-${field.key}`"
                  :row="totalRow"
                  :field="field"
                  :index="index">
              <component
                  v-if="field.type && field.type in definedTableFields"
                  :is="definedTableFields[field.type].component"
                  :field="field"
                  :row="totalRow"
                  :key="field.key + '-' + index"
                  :value="totalRow[field.key]"
              />
              <template v-else>
                {{ totalRow[field.key] }}
              </template>
            </slot>
          </template>
        </td>
        <td></td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script setup>
import definedTableFields from "./fields";
import {computed, ref, defineProps} from "vue";

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },

  data: {
    type: Array,
    required: true,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  hiddenFields: {
    type: Array,
    default: () => [],
  },

  notAllowedFields: {
    type: Array,
    default: () => [],
  },

  withMetrics: {
    type: Boolean,
    default: false,
  },

});

const hiddenFieldsValue = ref(props.hiddenFields);


const showedFields = computed(() => {
  let fields = props.columns.filter(
      (col) =>
          hiddenFieldsValue.value.indexOf(col.key) === -1 &&
          props.notAllowedFields.indexOf(col.key) === -1
  );

  return fields;
});

const hasTotals = computed(() => {
  return props.columns.some((col) => col.inTotals);
});

const totalRow = computed(() => {
  return props.data.reduce((acc, row) => {
    for (const key in row) {
      if (typeof Number(row[key]) === "number") {
        acc[key] = (Number(acc[key]) || 0) + Number(row[key]);
      }
    }
    return acc;
  }, {});
});

</script>

<style>
.progress-bar {
  width: 0%;
  height: 10px;
  background-color: rgb(var(--color-primary));
  animation: progress 3s linear infinite;
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.table-responsive {
  overflow-y: inherit !important;
  min-height: 40vh !important;
}

.hs-dropdown-menu-columns {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
