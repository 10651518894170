import {defineAsyncComponent} from "vue";

export default {
    text: {
        label: "Text Field",
        component: defineAsyncComponent(() => import('./TreeTextField.vue'))
    },

    date: {
        label: "Date Field",
        component: defineAsyncComponent(() => import('./TreeDateField.vue'))
    },
    enum: {
        label: "Enum Field",
        component: defineAsyncComponent(() => import('./TreeEnumField.vue'))
    },
}