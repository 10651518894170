<template>
  <div class="col-md-8 col-lg-6">
    <div class="card" style="height: 100%;">
      <div class="card-header" style="padding-bottom: 12px;padding-top: 12px;">
        <h3 class="card-title">الأرباح حسب المستخدمين</h3>
      </div>
      <div class="card-body">
        <vue-apex-charts type="bar" height="100%" ref="chart" :options="chartOptions" :series="series"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted , ref } from "vue";
import VueApexCharts from 'vue3-apexcharts'
import { useDashboard } from "../../services/useDashboard.js"

const { getOrdersByEditors } = useDashboard();

const chartValues = ref([])
const chart = ref();
const total = ref(0);

const chartOptions = ref({
  chart: {
    id: 'periodicalGains'
  },
  xaxis: {
    categories: []
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      borderRadiusApplication: 'end',
      horizontal: true,
    }
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return parseFloat(val * 100 / total.value ).toFixed(2) + "% (" + val + ")" ;
    },
    style: {
      fontSize: '12px',
    }
  },
  fill: {
  type: 'gradient',
  gradient: {
    shade: 'dark',
    shadeIntensity: 0.5,
    gradientToColors: ['#6c63ff'], // Adjust the subtle purple color here
    inverseColors: false,
    opacityFrom: 1,
    opacityTo: 1,
    stops: [0, 100]
  }
},

})

const series = ref([{
  name: 'الأرباح',
  data: chartValues
}])

onMounted(async () => {
  refreshData()
})

const refreshData = async () => {

  let response = await getOrdersByEditors()

  if(response.status){
    chartValues.value = response.data.orders

    total.value = 0
    chartValues.value.forEach((element,index) => {

      if(element==null){
        chartValues.value[index] = 0
      }else{
        total.value += parseFloat(element)
      }
    });

    chart.value.updateOptions({
      xaxis: {
        categories: response.data.users
      }})
  }
}

</script>
