<template>
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <h2>
              الطلبات
            </h2>
          </div>
        </div>
      </div>
    </div>
    <!-- Page body -->
    <div class="page-body" style="min-height: 75vh;">
      <div class="container-xl">
        <div class="row row-deck row-cards">
          <div class="col-12">
            <div class="card">
              <ListingView url="orders" :columns="ordersFields">

                <template #listing-body-column-actions="{row}">
                  <div class="flex space-x-3 rtl:space-x-reverse text-start min-w-[200px] truncate">
                    <button @click="showOrderDetails(row)"
                            class="badge badge-outline bg-primary text-white rounded rounded-circle">
                      <i class="fa fa-eye"></i>
                    </button>
                  </div>
                </template>

              </ListingView>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseModal title=" تفاصيل الطلب" id="order-details-modal" ref="orderDetailsModalRef">
      <template #content>
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <OrderTicket v-if="order" :order="order"/>
          </div>
        </div>
      </template>
    </BaseModal>

  </div>

</template>

<script setup>
import ListingView from "@/components/ListingView.vue";
import ordersFields from "@/data/orders";
import BaseModal from "@/components/BaseModal.vue";
import OrderTicket from "@/layouts/partials/header/OrderTicket.vue";
import {ref} from "vue";

const order = ref();
const orderDetailsModalRef = ref();

const showOrderDetails = (row) => {
  order.value = {...row};
  orderDetailsModalRef.value.show();
}

</script>

<style scoped></style>