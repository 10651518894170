import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index';

import 'vue-toast-notification/dist/theme-bootstrap.css';

import {useAuth} from "@/services/useAuth";
import {Model} from "vue-api-query";
import axios from "axios";

const {can} = useAuth();

let app = createApp(App)

app.use(router)

// 1. Add `can` as a global method
app.config.globalProperties.$can = can;

// 2. Add `v-can` as a directive
app.directive('can', {
    // The directive lifecycle hooks
    mounted(el, binding) {
        let ability = binding.arg + '.' + Object.keys(binding.modifiers)[0];

        if (!can(ability)) {
            el.style.display = 'none';
        }
    },
});

// inject global axios instance as http client to Model
Model.$http = axios

app.mount('#app');