import {createRouter, createWebHistory} from 'vue-router'
import {useAuth} from "@/services/useAuth";
import ordersRoutes from "@/router/orders";
import ChargesView from '@/views/ChargesView.vue';
import DashboardView from '@/views/dashboard/DashboardView.vue';

const {can, authenticated} = useAuth();

const InterestsView = () => import("../views/InterestsView");
const ElementForm = () => import("../views/elements/ElementForm");
const DocumentElementForm = () => import("../views/docs/DocumentElementForm");
const DocumentInputPreview = () => import("../views/docs/DocumentInputPreview.vue");
const UserManagementView = () => import("../views/UserManagementView.vue");
const UserActivity = () => import("../views/UserActivity.vue");

const routes = [
    {
        path: '/',
        name: 'home',
        component: InterestsView,
        meta: {
            permissions: ['department.index']
        }
    },
    {
        path: '/interests',
        name: 'interests',
        component: InterestsView,
        meta: {
            permissions: ['department.index'],
        }
    },
    {
        path: '/interest/:id/docs',
        name: 'interest-docs',
        component: () => import('@/views/DocsView.vue'),
        props: true,
        meta: {
            permissions: ['service.index']
        }
    },
    {
        path: '/interest/:id/links',
        name: 'interest-links',
        component: () => import('@/views/LinksView.vue'),
        props: true,
        meta: {
            permissions: ['service.index']
        }
    },
    {
        path: '/interest/:department_id/links/create',
        name: 'links-create',
        component: ElementForm,
        props: true,
        meta: {
            permissions: ['service.create'],
            auth: true
        }
    }
    ,
    {
        path: '/interest/:interest_id/elements/create-document',
        name: 'elements-create-document',
        component: DocumentElementForm,
        props: true,
        meta: {
            permissions: ['service.create'],
            auth: true
        }
    }
    ,
    {
        path: '/elements/:id/update-document',
        name: 'elements-update-document',
        component: DocumentElementForm,
        props: true,
        meta: {
            permissions: ['service.update'],
            auth: true
        }

    }
    ,
    {
        path: '/elements/:id/preview-document',
        name: 'elements-preview-document',
        component:
        DocumentInputPreview,
        props: true,
        meta: {
            permissions: ['service.browse']
        }
    }
    ,
    {
        path: '/elements/:id',
        name: 'elements-update',
        component: ElementForm,
        props: true,
        meta: {
            permissions: ['service.update'],
            auth: true
        }
    },

    {
        path: '/users',
        name: 'users',
        component: UserManagementView,
        props: true,
        meta: {
            permissions: ['user.index'],
            auth: true
        }
    },

    {
        path: '/users/:id',
        name: 'user-activity',
        component:
        UserActivity,
        props: true,
        meta: {
            permissions: ['user.browse'],
            auth: true
        }
    },

    {
        path: '/charges',
        name: 'charges',
        component: ChargesView,
        props: true,
        meta: {
            permissions: ['charges.index'],
            auth: true
        }
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
        props: true,
        meta: {
            auth: true
        }
    },

    ...ordersRoutes,
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {

    if (!to?.meta?.auth) {
        //No authentication required. Proceeding.
        next();
        return;
    }

    if (to?.meta?.auth === true && !authenticated()) {
        //Authentication required, but user authenticated. Proceeding
        next();
        return;
    }

    //console.log("Checking permissions:", to?.meta?.permissions);

    if (to?.meta?.permissions) {
        if (!can(to?.meta?.permissions)) {
            //User does not have required permissions. Blocking navigation.
            next('unauthorized');
            return;
        }
    }

    //User has required permissions. Proceeding.
    next();
});


export default router
