<template>
  <vue3-html2pdf :show-layout="false"
                 :float-layout="false"
                 :enable-download="true"
                 :preview-modal="false"
                 :paginate-elements-by-height="942"
                 :filename="props.filename"
                 :pdf-quality="2"
                 :manual-pagination="false"
                 pdf-format="a4"
                 pdf-orientation="portrait"
                 pdf-content-width="796px"
                 lang="ar"
                 ref="html2PdfRef">
    <template v-slot:pdf-content>
      <slot name="content">

      </slot>
      <!-- PDF Content Here -->
    </template>
  </vue3-html2pdf>
</template>

<script setup>
import Vue3Html2pdf from 'vue3-html2pdf'
import {ref, defineProps, defineExpose} from "vue";

const props = defineProps({
  filename: {
    type: String,
    required: false,
    default: 'file_name'
  }
})

const html2PdfRef = ref(null)


const loading = ref({printing: false});

const print = async () => {
  loading.value.printing = true;
  await html2PdfRef.value.generatePdf();
  loading.value.printing = false;
}

defineExpose({
  print,
  loading,
})

</script>

<style scoped>

</style>