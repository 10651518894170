<template>

  <div class="col-md-8 col-lg-6">
    <div class="card">
      <div class="card-header" style="padding-bottom: 12px;padding-top: 12px;">
        <h3 class="card-title">المصاريف الحديثة</h3>
      </div>
      <table class="table card-table table-vcenter">
        <thead>
          <tr>
            <th>المصروف</th>
            <th>المجموع</th>
            <th>التاريخ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(charge, index) in charges" :key="index">

            <td>
              {{ charge.name }}
            </td>

            <td>
              {{ charge.amount }}
            </td>

            <td>
              {{ charge.spent_at }}
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  

</template>

<script setup>

import { onMounted, ref } from "vue";
import { useDashboard } from "../../services/useDashboard.js"

const { getRecentCharges } = useDashboard();

const charges = ref([])

onMounted(async () => {
  refreshData()
})

const refreshData = async () => {
  let response = await getRecentCharges()
  if(response.status){
    charges.value = response.data.charges
  }
}

</script>

<style scoped></style>