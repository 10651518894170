<template>

    <div class="col-md-8 col-lg-6">
      <div class="card">
        <div class="card-header" style="padding-bottom: 12px;padding-top: 12px;">
          <h3 class="card-title">أكثر الخدمات المربحة</h3>
        </div>
        <table class="table card-table table-vcenter">
          <thead>
            <tr>
              <th>الصورة</th>
              <th>الخدمة</th>
              <th colspan="2">المجموع</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(service, index) in services" :key="index">

              <td>
                <img :src="service.service_image" style="width: 30px;height: 20px;"/>
              </td>

              <td>
                {{ service.service_title }}
              </td>

              <td>
                {{ service.revenue }}
              </td>
              
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    

</template>

<script setup>

import { onMounted, ref } from "vue";
import { useDashboard } from "../../services/useDashboard.js"

const { getTopServices } = useDashboard();

const services = ref([])

onMounted(async () => {
  refreshData()
})

const refreshData = async () => {
  let response = await getTopServices()
  if(response.status){
    services.value = response.data.services
  }
}

</script>

<style scoped></style>