<template>
  <div class="page-wrapper">
    <!-- Page header -->
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col">
            <h2>
              المصاريف
            </h2>
          </div>
          <!-- Page title actions -->
          <div class="col-auto ms-auto d-print-none">
            <div class="btn-list">
              <div class="row" :style="{ width: '100%' }">
                <div class="col" style="margin-left: 10px;">

                  <button v-can:charges.create class="btn btn-primary" type="submit" @click="addNewCharge"
                          style="margin-right: auto;">
                    <div class="row">
                      <div class="col">
                        إضافة مصروف
                        <IconCirclePlus :size="16" class="text-white"/>
                      </div>
                    </div>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page body -->
    <div class="page-body" style="min-height: 75vh;">
      <div class="container-xl">
        <div class="row row-deck row-cards">
          <div class="col-12">
            <div class="card">
              <div class="table-responsive">
                <table class="table card-table table-vcenter text-nowrap datatable">
                  <thead>
                  <tr>
                    <th>الاسم</th>
                    <th>المجموع</th>
                    <th>التاريخ</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(charge, index) in charges" :key="index">
                    <td>
                      {{ charge.name }}
                    </td>
                    <td>
                      {{ charge.amount }}
                    </td>
                    <td>
                      {{ charge.spent_at }}
                    </td>

                    <td class="text-end">
                      <div class="col-6">

                        <select class="form-select" @change="actionSelected(charge)" v-model="selectedOption">
                          <option hidden value="">إختيارات</option>
                          <option v-can:charges.update value="update">
                            تعديل
                          </option>
                          <option v-can:charges.delete value="delete">
                            حذف
                          </option>
                        </select>
                      </div>
                    </td>

                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Input Modal -->
  <BaseModal :visible="editModalVisible">
    <template #content>
      <div class="modal-dialog modal-lg modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" v-if="addChargeBool">
              إضافة مصروف
            </h5>
            <h5 class="modal-title" v-else>
              تعديل مصروف
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="row">
              <div class="col-sm-6">
                <div class="mb-3">
                  <label class="form-label">
                    الاسم
                  </label>
                  <input type="text" class="form-control" v-model="selectedCharge.name" placeholder="الاسم">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="mb-3">
                  <label class="form-label">
                    المجموع
                  </label>
                  <input type="number" class="form-control" v-model="selectedCharge.amount" placeholder="المجموع">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="mb-3">
                  <label class="form-label">
                    التاريخ
                  </label>
                  <input v-model="selectedCharge.spent_at" type="date" class="form-control" placeholder="التاريخ">
                </div>
              </div>
            </div>


          </div>
          <div class="modal-footer d-flex justify-content-end">
            <a href="#" class="btn btn-link link-secondary" @click="hideUpdateModal">
              الغاء
            </a>
            <div v-if="!loading" class="d-flex">
              <button type="button" class="btn btn-primary ms-2" v-if="addChargeBool" @click="onClickCreateNewCharge">
                <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                     stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5l0 14"></path>
                  <path d="M5 12l14 0"></path>
                </svg>
                إضافة
              </button>

              <button type="button" class="btn btn-primary ms-2" v-else @click="onClickUpdateCharge">
                <!-- Download SVG icon from http://tabler-icons.io/i/plus -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                     stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 5l0 14"></path>
                  <path d="M5 12l14 0"></path>
                </svg>
                حفظ
              </button>
            </div>
            <button v-else type="button" class="btn btn-primary ms-2" disabled>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              جاري الحفظ...
            </button>
          </div>

        </div>
      </div>
    </template>
  </BaseModal>
  <!-- End Edit Input Modal -->
</template>

<script setup>

import {onMounted, ref} from "vue";
import BaseModal from "@/components/BaseModal";
import {useToast} from "vue-toast-notification";
import {useCharges} from "../services/useCharges"
import {IconCirclePlus} from '@tabler/icons-vue';


const {getAllCharges, createCharge, updateCharge, deleteCharge} = useCharges();

const editModalVisible = ref(false);
const loading = ref(false);

const selectedCharge = ref({
  id: "",
  name: "",
  amount: "",
  spent_at: ""
});

const addChargeBool = ref(false);
const selectedOption = ref("");

const charges = ref([])

onMounted(async () => {
  refreshData()
})

const refreshData = async () => {

  let response = await getAllCharges()

  if (response.status) {
    charges.value = response.data
  }
}

const showUpdateModal = (charge) => {
  addChargeBool.value = false
  selectedCharge.value = {...charge}
  editModalVisible.value = true;
}

const deleteSelectedCharge = async (charge) => {
  let response = await deleteCharge(charge)
  if (response.status) {
    refreshData()
    useToast().success("تمت الحذف بنجاح")
  }
}

const hideUpdateModal = () => {
  editModalVisible.value = false;
}

const addNewCharge = () => {

  addChargeBool.value = true
  selectedCharge.value = {
    id: "",
    name: "",
    amount: "",
    spent_at: ""
  }
  editModalVisible.value = true;
}

const onClickCreateNewCharge = async () => {
  loading.value = true
  let response = await createCharge(selectedCharge.value)
  if (response.status) {
    refreshData()
    useToast().success("تمت الإضافة بنجاح")
  }

  editModalVisible.value = false
  loading.value = false
}

const onClickUpdateCharge = async () => {
  loading.value = true

  let response = await updateCharge(selectedCharge.value)
  if (response.status) {
    refreshData()
    useToast().success("تمت التغيير بنجاح")
  }

  editModalVisible.value = false
  loading.value = false
}


const actionSelected = (charge) => {
  if (selectedOption.value == "update") {
    showUpdateModal(charge)
  } else if (selectedOption.value == "delete") {
    deleteSelectedCharge(charge)
  }
  selectedOption.value = ""
}

</script>

<style scoped></style>