import Model from "./Model.js";

export default class ResourceModel extends Model {
    static setModelResource(resource) {
        const instance = new ResourceModel();
        instance._resource = resource;

        return instance;
    }

    resource() {
        return this._resource;
    }
}