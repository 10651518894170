import {api} from "@/boot/axios";

const getAllCharges= async ()=>{
    let response = {
        status:false,
        data:{}
    }
    await api.get('charges').then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })
    return response
}

const createCharge= async (charge)=>{

    let response = {
        status:false,
        data:{}
    }
    let data = new FormData();

    Object.keys(charge).forEach((key) => {
        data.append(key, charge[key]);
    });

    await api.post('charges',data).then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })

    return response
}

const updateCharge= async (charge)=>{
    let response = {
        status:false,
        data:{}
    }
    let data = new FormData();

    Object.keys(charge).forEach((key) => {
        data.append(key, charge[key]);
    });

    await api.post('update/charges/'+charge.id,data).then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })

    return response
}

const deleteCharge= async (charge)=>{
    let response = {
        status:false,
        data:{}
    }

    await api.delete('charges/'+charge.id).then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })

    return response
}

export function  useCharges(){
   return {
       createCharge,
       updateCharge,
       deleteCharge,
       getAllCharges
   }
}