import {Model as BaseModel} from 'vue-api-query'

export default class Model extends BaseModel {

    // Define a base url for a REST API
    baseURL() {
        // return "http://localhost:8000/api";
        return "https://moonvoy.website/maslaha_backend/public/api";
    }

    // Implement a default request method
    request(config) {
        let token = localStorage.getItem('userToken');
        if (token) {
            config['headers'] = {
                Authorization: `Bearer ${token}`,
            }
        }
        return this.$http.request(config)
    }
}