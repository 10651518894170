<template>
  <div class="navbar-expand-md">
    <div class="collapse navbar-collapse" id="navbar-menu">
      <div class="navbar navbar-light">
        <div class="container-xl">
          <ul class="navbar-nav">

            <li class="nav-item" :class="{ 'active': $route.name === 'dashboard' }">
              <router-link class="nav-link" :to="{ name: 'dashboard' }">
                <span class="nav-link-icon d-md-none d-lg-inline-block mx-2"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <IconLayoutDashboard :size="24" class="icon"/>
                </span>
                <span class="nav-link-title">لوحة التحكم</span>
              </router-link>
            </li>
            
            <li v-can:department.index class="nav-item"
                :class="{ 'active': $route.name === 'interests' }">
              <router-link class="nav-link" to="/interests">
                <span class="nav-link-icon d-md-none d-lg-inline-block"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <IconHome :size="24" class="icon"/>
                </span>
                <span class="nav-link-title">المصالح</span>
              </router-link>
            </li>

            <li v-can:user.index class="nav-item" :class="{ 'active': $route.name === 'users' }">
              <router-link class="nav-link" to="/users">
                <span class="nav-link-icon d-md-none d-lg-inline-block mx-2"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <IconUsers :size="24" class="icon"/>
                </span>
                <span class="nav-link-title">المستخدمين</span>
              </router-link>
            </li>

            <li v-can:user.index class="nav-item" :class="{ 'active': $route.name === 'charges' }">
              <router-link class="nav-link" to="/charges">
                <span class="nav-link-icon d-md-none d-lg-inline-block mx-2"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-currency-dollar"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" /><path d="M12 3v3m0 12v3" /></svg>
                </span>
                <span class="nav-link-title">المصاريف</span>
              </router-link>
            </li>

            <li class="nav-item" :class="{ 'active': $route.name === 'orders' }">
              <router-link class="nav-link" :to="{ name: 'orders' }">
                <span class="nav-link-icon d-md-none d-lg-inline-block mx-2"><!-- Download SVG icon from http://tabler-icons.io/i/home -->
                  <IconShoppingCart :size="24" class="icon"/>
                </span>
                <span class="nav-link-title">الطلبات</span>
              </router-link>
            </li>

          </ul>
          <div class="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last">
            <div>
              <div class="input-icon">
                    <span class="input-icon-addon">
                      <!-- Download SVG icon from http://tabler-icons.io/i/search -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                           stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                           stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="10"
                                                                                                              cy="10"
                                                                                                              r="7"/><line
                          x1="21" y1="21" x2="15" y2="15"/></svg>
                    </span>
                <input @keyup="loadData" type="text" class="form-control" placeholder="بحث..."
                       aria-label="Search in website" v-model="keyword">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {useInterests} from "@/services/useInterests";
import {IconUsers, IconHome , IconShoppingCart, IconLayoutDashboard} from '@tabler/icons-vue';

const {fetchAll} = useInterests();
const keyword = ref('');
const timer = ref(undefined);
const loadData = () => {
  clearImmediate(timer);
  timer.value = setTimeout(() => {
    fetchAll(keyword.value);
  }, 2000);
}
</script>

<style scoped>

</style>