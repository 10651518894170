import OrdersView from "@/views/OrdersView.vue";

export default [
    {
        path: '/orders',
        name: 'orders',
        component: OrdersView,
        meta: {
            permissions: ['orders.index'],
            auth: true
        }
    },
]