import {reactive} from "vue";

const ordersFields = reactive([
    {
        key: "ref",
        type: "text",
        label: "الرقم",
    },

    {
        key: "name",
        type: "text",
        label: "الاسم",
    },
    {
        key: "phone",
        type: "text",
        label: "الهاتف",
    },
    {
        key: "total_price",
        type: "text",
        label: "المبلغ",
    },
    {
        key: "status",
        type: "enum",
        label: "الحالة",
        options: [
            {
                label: "معلق",
                value: "pending",
                color: "warning",
            },
            {
                label: "تم الدفع",
                value: "paid",
                color: "success",
            },
            {
                label: "تم الشحن",
                value: "shipped",
                color: "primary",
            },
            {
                label: "تم التوصيل",
                value: "delivered",
                color: "success",
            },
            {
                label: "ملغي",
                value: "canceled",
                color: "danger",
            },
        ]
    },
    {
        key: "created_at",
        label: "التاريخ",
        type: "date",
        valueFormat: "DD/MM/YYYY HH:mm",
    },
    {
        key: "actions",
        label: "",
    },
]);

export default ordersFields;