<template>

  <div class="row">

    <div class="col-md-4">
      <div class="card card-sm">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <span class="bg-green text-white avatar">
                <!-- Download SVG icon from http://tabler-icons.io/i/trending-up -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <polyline points="3 17 9 11 13 15 21 7"/>
                  <polyline points="14 7 21 7 21 14"/>
                </svg>
              </span>

            </div>
            <div class="col">
              <div class="font-weight-medium">
                المدخول
              </div>
              <div class="text-secondary">
                {{ gains }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card card-sm">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <span class="bg-red text-white avatar">
                <!-- Download SVG icon from http://tabler-icons.io/i/trending-down -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <polyline points="3 7 9 13 13 9 21 15"/>
                  <polyline points="14 15 21 15 21 8"/>
                </svg>
              </span>

            </div>
            <div class="col">
              <div class="font-weight-medium">
                المصاريف 
              </div>
              <div class="text-secondary">
                {{ charges }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card card-sm">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <span class="bg-primary text-white avatar"><!-- Download SVG icon from http://tabler-icons.io/i/currency-dollar -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"></path><path d="M12 3v3m0 12v3"></path></svg>
              </span>
            </div>
            <div class="col">
              <div class="font-weight-medium">
                الأرباح 
              </div>
              <div class="text-secondary">
                {{ profit }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>

</template>

<script setup>

import { onMounted , ref } from "vue";
import { useDashboard } from "../../services/useDashboard.js"

const { getTotals } = useDashboard();

const gains = ref();
const charges = ref();
const profit = ref();

onMounted(async () => {
  refreshData()
})

const refreshData = async () => {
  let response = await getTotals()
  if (response.status) {
    gains.value = response.data.gains
    charges.value = response.data.charges
    profit.value = response.data.profit
  }
}

</script>

<style scoped></style>