<template>
  <div class="card-footer d-flex align-items-center">
    <p class="m-0 text-secondary">Showing <span>{{ props.form }}</span> to <span>{{ to }}</span> of <span>{{
        totalOfRecords
      }}</span> entries</p>
    <ul class="pagination m-0 ms-auto">
      <li class="page-item" :class="{ 'disabled': currentPageValue <= 1 }">
        <button class="page-link"
                @click="onClickPrevious"
                :disabled="currentPageValue <= 1"
                tabindex="-1" aria-disabled="true">
          <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
               stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M15 6l-6 6l6 6"></path>
          </svg>
          prev
        </button>
      </li>

      <li v-for="(page  , index ) in pages"
          :key="index"
          @click="onClickPage(page)"
          :class="{ 'active': currentPageValue === page}"
          class="page-item"><a class="page-link" href="#">{{ page }}</a></li>

      <li class="page-item" :class="{ 'disabled': currentPageValue >= pages }">
        <button class="page-link"
                @click="onClickNext"
                :disabled="currentPageValue >= pages">
          next <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
               stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M9 6l6 6l-6 6"></path>
          </svg>
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, computed} from "vue";

const props = defineProps({
  from: {
    type: Number,
    default: 0,
  },
  to: {
    type: Number,
    default: 0,
  },
  totalOfRecords: {
    type: Number,
    default: 0,
  },

  pages: {
    type: Number,
    default: 1,
    description: 'Total number of pages',
  },

  currentPage: {
    type: Number,
    default: 1,
    description: 'Current page',
  },
})

const emits = defineEmits(["update:currentPage"])

const currentPageValue = computed({
  get: () => props.currentPage,
  set: (value) => emits('update:currentPage', value)
})

const onClickNext = () => {
  if (currentPageValue.value < props.pages) {
    currentPageValue.value++
  }
}

const onClickPrevious = () => {
  if (currentPageValue.value > 1) {
    currentPageValue.value--
  }
}

const onClickPage = (page) => {
  currentPageValue.value = page
}

</script>


<style scoped>

</style>