<template>
  <div class="modal fade"
       :id="id"
       tabindex="-1"
       role="dialog"
       aria-labelledby="modal-block-small"
       aria-hidden="true"
       ref="modalRef">
    <div v-if="title" class="modal-header">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script setup>

import {Modal} from "bootstrap";
import {onBeforeUnmount, ref, watch, onMounted, defineProps, defineExpose} from "vue";

const modalRef = ref(null);
const modal = ref(null);

const props = defineProps({
  title: {
    type: String,
    default: 'Modal Title',
    required: true
  },
  editable: {
    type: Boolean,
    required: false,
    default: false
  },
  saved: {
    type: Boolean,
    required: false,
    default: false
  },
  visible: {
    type: Boolean,
    required: false,
    default: false
  },
  done: {
    type: Function,
    default: null,
    required: false
  },
  modalClass: {
    type: String,
    default: ''
  },

  id: {
    type: String,
    default: 'modal-block-normal'
  }
});

onMounted(() => {
  modal.value = new Modal(modalRef.value, {
    keyboard: false,
    backdrop: true
  });
  if (props.visible) {
    modal.value.show();
  }
})

onBeforeUnmount(() => {
  modal.value.dispose();
})

watch(() => props.visible, (newValue) => {
  if (newValue) {
    modal.value.show();
  } else {
    modal.value.hide();
  }
});

const show = () => {
  console.log('showing modal')
  modal.value.show();
}

defineExpose({
  show,
})

</script>

<style scoped>

</style>