import axios from "axios";
import {useToast} from "vue-toast-notification";

const api = axios.create({
    baseURL: "https://moonvoy.website/maslaha_backend/public/api/",
  //baseURL: "http://localhost:8000/api/",
})

api.interceptors.request.use(
    async config => {
        config.headers = {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

api.interceptors
    .response.use(
    async res => {
        return res;
    }, error => {
        console.log(error)
        if (error.response.status === 422) {
            Object.values(error.response.data.errors).map(errorMessages => {
                useToast().error(errorMessages.join('\n'));
            })
        }
    });

export {
    api
}