<template>
  <div class="nav-item dropdown d-none d-md-flex me-3">
    <button ref="dropdownBtn" class="nav-link px-0"
            data-bs-toggle="dropdown"
            tabindex="-1"
            aria-label="Show notifications">
      <i class="fa fa-shopping-cart"></i>
      <span v-if="shoppingCart.length" class="badge bg-warning text-white fs-6">
        {{ shoppingCart.length }}
      </span>
    </button>
    <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">
            سلة المشتريات
          </h3>
        </div>

        <div class="list-group list-group-flush list-group-hoverable">

          <template v-if="!order">
            <div v-for="(item , index) in shoppingCart"
                 :key="index"
                 class="list-group-item d-flex justify-content-between">
              <div class="d-flex align-items-center" style="min-width: 300px">
                <span class="avatar me-3 rounded" :style="`background-image: url(${item.image})`"></span>
                <div>
                  <div>
                    {{ item.title }}
                  </div>
                  <div class="text-secondary">
                    {{ item.price }} د.م <strong>x</strong> {{ item.quantity }}
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center align-content-center rounded rounded-circle">
                <button class="btn btn-sm btn-danger" @click="removeFromCart(item)">
                  <i class="fa fa-trash-alt"></i>
                </button>
              </div>
            </div>

            <div v-if="shoppingCart.length === 0" class="list-group-item">
              <div class="row align-items-center">
                <div class="col text-truncate">
                  <div class="d-block text-muted text-truncate mt-n1">
                    لا يوجد منتجات في سلة المشتريات
                  </div>
                  <div class="d-block text-muted text-truncate mt-n1 text-center">
                    <i class="fa fa-shop-lock fa-2x my-3"></i>
                  </div>
                </div>
              </div>
            </div>

            <!-- name and phone  inputs-->
            <div v-if="shoppingCart.length > 0" class="list-group-item d-flex justify-content-end w-100">
              <div class="row w-100">
                <div class="col-sm-6">
                  <div class="mb-3">
                    <label class="form-label fs-6 text-end">
                      الهاتف
                    </label>
                    <input type="text"
                           v-model="phone"
                           class="form-control form-control-sm text-end"
                           name="example-text-input"
                           placeholder="الهاتف">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="mb-3">
                    <label class="form-label fs-6 text-end">
                      الاسم
                    </label>
                    <input type="text"
                           v-model="name"
                           class="form-control form-control-sm text-end"
                           name="example-text-input"
                           placeholder="الاسم">
                  </div>
                </div>
              </div>
            </div>

            <!-- total -->
            <div v-if="shoppingCart.length" class="list-group-item d-flex justify-content-between">
              <div class="d-flex align-items-center" style="min-width: 300px">
                <div>
                  <div>
                    الاجمالي
                  </div>
                  <div class="text-secondary">
                    {{ shoppingCart.reduce((acc, item) => acc + item.price * item.quantity, 0) }} د.م
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-center align-items-center align-content-center rounded rounded-circle">
                <button :disabled="loading.saveOrder"
                        @click="onClickSave"
                        class="btn btn-sm btn-primary">
                  <i v-if="!loading.saveOrder" class="fa fa-shopping-cart mx-2"></i>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                        v-else></span>
                  اتمام الشراء
                </button>
              </div>
            </div>

          </template>

          <!-- Btn print -->
          <div v-if="order" class="list-group-item d-flex justify-content-between">
            <div class="d-flex align-items-center" style="min-width: 300px">
              <div>
                <div>
                  <button @click="onClickPrint" class="btn btn-sm btn-primary mb-2">
                    <i class="fa fa-print mx-2"></i>
                    طباعة الفاتورة
                  </button>

                  <A4Printer ref="printerRef">
                    <template #content>
                      <OrderTicket :order="order"/>
                    </template>
                  </A4Printer>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import useOrders from "@/services/useOrders";
import {onMounted, ref, watch} from "vue";
import {useToast} from "vue-toast-notification";
import OrderTicket from "@/layouts/partials/header/OrderTicket.vue";
import A4Printer from "@/components/A4Printer.vue";

const {shoppingCart, storeOrder, loading} = useOrders();

const dropdownBtn = ref(null)
const printerRef = ref(null)

const name = ref()
const phone = ref()
const order = ref(null)

watch(() => shoppingCart.value, () => {
  dropdownBtn.value.click();
  if (order.value) {
    order.value = null;
  }
}, {deep: true})

onMounted(() => {
  shoppingCart.value = JSON.parse(localStorage.getItem('shoppingCart')) || []
  dropdownBtn.value.click();
})

const removeFromCart = (item) => {
  shoppingCart.value = shoppingCart.value.filter(i => i.id !== item.id)
  setTimeout(() => {
    dropdownBtn.value.click();
  }, 100);
}

const onClickSave = async () => {
  setTimeout(() => {
    dropdownBtn.value.click();
  }, 100);
  let data = {
    items: shoppingCart.value.map(item => {
      return {
        id: item.id,
        quantity: item.quantity
      }
    }),
    phone: phone.value,
    name: name.value
  }

  order.value = await storeOrder(data);
  if (order.value) {
    useToast().success('تم ارسال الطلب بنجاح')
  }

  setTimeout(() => {
    dropdownBtn.value.click();
  }, 100);

}

const onClickPrint = async () => {

  printerRef.value.print();

  setTimeout(() => {
    dropdownBtn.value.click();
  }, 100);
}

</script>


<style>

table.table ul.dropdown-menu {
  position: relative;
  float: none;
  max-width: 160px;
}

</style>