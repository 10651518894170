import {ref} from "vue";
import {api} from "@/boot/axios";
import {useToast} from "vue-toast-notification";

const interests = ref([]);
const errors = ref([]);
const loading = ref(false);
const interest = ref({});

const fetchAll = async (keyword) => {
    loading.value = true;
    await api.get("departments", {
        params: {
            paginate_nbr: 10,
            keyword: keyword
        }
    }).then((res) => {
        interests.value = res.data.data;
    }).catch((error) => {
        errors.value = error.response.message;
        console.log(errors.value)
    })
    loading.value = false
    ;

}

const fetchById = async (id) => {
    loading.value = true;
    await api.get("departments/" + id, {}).then((res) => {
        interest.value = res.data.department;
    }).catch((error) => {
        errors.value = error.response.message;
    })
    loading.value = false;
}

const update = async (department) => {
    loading.value = true;

    let status = false;
    let data = new FormData();

    Object.keys(department).forEach((key) => {
        data.append(key, department[key]);
    });

    await api.post("departments/" + department.id, data)
        .then((res) => {
            interest.value = res.data.department;
            department = res.data.department;
            useToast().success("لقد تم الحفظ بنجاح");
            status = true;
        })
        .catch((error) => {
            console.log(error)
            useToast().error("لم يتم الحفظ , الرجاء المحاولة لاحقا");
        })

    loading.value = false;
    return status;
}
const create = async (department) => {
    let status = false;
    let data = new FormData();

    Object.keys(department).forEach((key) => {
        data.append(key, department[key]);
    });

    await api.post('departments', data).then(res => {
        interest.value = res.data.department;
        department = res.data.department;
        status = true;
    }).catch((errs => {
        console.log(errs);
    }));
    return status;
}

const remove = async () => {
    await api.delete('departments/' + interest.value.id, interest.value).then(res => {
        console.log(res);
    }).catch(error => {
        console.log(error);
    })
}


export function useInterests() {
    return {
        interests,
        interest,
        loading,

        fetchAll,
        fetchById,
        create,
        update,
        remove,
    }
}