<template>
  <BaseTable :data="data" :columns="columns" class="table-responsive">
    <!-- Custom Fields Values -->
    <template
        v-for="(col, slotName, index) in listingSlotsColumns"
        :key="index"
        #[slotName]="{ row, field }"
    >
      <slot :name="`listing-${slotName}`" :row="row" :field="field"></slot>
    </template>
    <!-- END Custom Fields Values -->
  </BaseTable>

  <ListingPagination v-model:current-page="currentPage"
                     :pages="totalOfPages"
                     :from="from"
                     :to="to"
                     :totalOfRecords="data.length"/>
</template>

<script setup>
import {onMounted, defineProps, watch, computed, useSlots} from "vue";
import ListingPagination from "@/components/listing/ListingPagination.vue";
import BaseTable from "@/components/table/BaseTable.vue";
import {useListing} from "@/services/useListing";

const props = defineProps({
  url: {
    type: String,
    required: true,
  },

  columns: {
    type: Array,
    required: true,
  },
})

const {fetchData, data, totalOfPages, from, to, currentPage} = useListing(props.url);

const slots = useSlots();

onMounted(async () => {
  await fetchData();
})

watch(() => [
  currentPage
], async () => {
  await fetchData();
}, {deep: true});


const listingSlotsColumns = computed(() => {
  return Object.fromEntries(
      Object.entries(slots)
          .filter(([value]) => value.startsWith("listing-body-column-"))
          .map(([key, value]) => [key.replace("listing-", ""), value])
  );
});

</script>

<style scoped>

</style>