import {ref} from "vue";
import ResourceModel from "../models/ResourceModel.js";
import {api} from "@/boot/axios";

export function useListing(url) {

    const data = ref([]);
    const record = ref({});
    const totalOfRecords = ref(0)
    const totalOfPages = ref(1)
    const currentPage = ref(1)
    const perPage = ref(10)
    const from = ref(null)
    const to = ref(null)

    const errors = ref({});
    const loading = ref({
        data: false,
        delete: false,
        create: false,
        update: false,
        fetch: false,
    });

    const fetchData = async (filters, includes, globalSearch, sortBy = "-id", metrics = []) => {
        loading.value.data = true;
        let query = await ResourceModel
            .setModelResource(url)
            .where({...filters})
            .orderBy(sortBy)
            .include(Array.isArray(includes) ? includes : [])
            .params({
                global_search: globalSearch,
                metrics: metrics,
            })
            .page(currentPage.value)
        if (perPage.value) {
            query = await query.limit(perPage.value)
        }
        await query.get()
            .then(result => {
                if (perPage.value) {
                    data.value = result.data;
                    if (result.meta) {
                        totalOfRecords.value = result.meta.total;
                        totalOfPages.value = result.meta.last_page;
                        from.value = result.meta.from ?? 0;
                        to.value = result.meta.to;
                        if (currentPage.value > result.meta.last_page) {
                            currentPage.value = result.meta.last_page;
                        }
                    } else {
                        totalOfRecords.value = result.total;
                        totalOfPages.value = result.last_page;
                        from.value = result.from;
                        to.value = result.to;
                        if (currentPage.value > result.last_page) {
                            currentPage.value = result.last_page;
                        }
                    }
                } else {
                    data.value = result;
                }
            })
            .catch(error => {
                errors.value = error;
            });
        loading.value.data = false;

        return data.value;
    }

    const deleteById = async (id) => {
        loading.value.delete = true;
        await api.delete(`${url}/${id}`)
            .then(() => {
                data.value = data.value.filter(item => item.id !== id);
            })
            .catch(error => {
                errors.value = error;
            });
        loading.value.delete = false;
    }

    const create = async (item) => {
        loading.value.create = true;
        let record = null;
        await api.post(url, item)
            .then(response => {
                record = Object.values(response.data)[0];
            })
            .catch(error => {
                if (error.response.status === 422) {
                    errors.value = error.response.data.errors;
                } else {
                    errors.value = error;
                }
            });
        loading.value.create = false;
        return record;
    }

    const fetchById = async (id, includes) => {
        loading.value.fetch = true;
        await ResourceModel.setModelResource(url)
            .include(Array.isArray(includes) ? includes : [])
            .find(id)
            .then(result => {
                record.value = result.record;
            })
            .catch(error => {
                errors.value = error;
            });
        loading.value.fetch = false;
        return record.value;
    }

    const update = async (item) => {
        loading.value.update = true;
        let record = null;
        await api.put(`${url}/${item.id}`, item)
            .then(response => {
                record = Object.values(response.data)[0];
            })
            .catch(error => {
                if (error.response.status === 422) {
                    errors.value = error.response.data.errors;
                } else {
                    errors.value = error;
                }
            });
        loading.value.update = false;
        return record;
    }

    return {
        data,
        record,
        errors,
        loading,
        totalOfRecords,
        totalOfPages,
        currentPage,
        perPage,
        from,
        to,


        fetchData,
        deleteById,
        create,
        fetchById,
        update,
    }
}
