<template>
  <header class="navbar navbar-expand-md navbar-light d-print-none">
    <div class="container-xl">

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
        <span class="navbar-toggler-icon"></span>
      </button>

      <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
        Maslaha - مصلحة
        <a href=".">
          <img src="../../assets/logo.png" width="48" height="48" alt="maslaha"
               class="navbar-brand-image">
        </a>
      </h1>

      <div class="navbar-nav flex-row order-md-last">
        <a href="?theme=dark" class="nav-link px-0 hide-theme-dark" title="Enable dark mode" data-bs-toggle="tooltip"
           data-bs-placement="bottom">
          <!-- Download SVG icon from http://tabler-icons.io/i/moon -->
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
               stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"/>
          </svg>
        </a>

        <a href="?theme=light" class="nav-link px-0 hide-theme-light" title="Enable light mode" data-bs-toggle="tooltip"
           data-bs-placement="bottom">
          <!-- Download SVG icon from http://tabler-icons.io/i/sun -->
          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
               stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="12" r="4"/>
            <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"/>
          </svg>
        </a>

        <ShoppingCartHeader/>

        <div class="nav-item dropdown">
          <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
            <span class="avatar avatar-sm" v-if="image" style="margin-left: 5px;border-radius: 50%;">
              <!-- Use 'img' element with 'src' binding -->
              <img :src="image" alt="User Avatar" class="avatar-img"
                   style="object-fit: cover;width: 100%; height: 100%;border-radius: 50%;"/>
            </span>
            <span class="avatar avatar-sm" v-else
                  style="background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeeUl9IZDN97pBQNgeunx6dD1df-4g7vkPFw&usqp=CAU)">
            </span>
            <div class="d-none d-xl-block ps-2">
              <div>{{ user?.name }}</div>
              <div class="mt-1 small text-muted">{{ user?.roles?.length > 0 ? user?.roles[0].name : '' }}</div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <a href="#" class="dropdown-item">الملف الشخصي</a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item">إعدادات</a>
            <a role="button" @click="submitLogout" class="dropdown-item">تسجيل خروج</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>

import {useAuth} from "@/services/useAuth";
import router from "@/router";
import {ref, onMounted} from "vue";
import ShoppingCartHeader from "@/layouts/partials/header/ShoppingCartHeader.vue";

const {logout, user} = useAuth();

const image = ref(null)

onMounted(async () => {
  if (user.value.avatar != null) {
    image.value = user.value.avatar_url
  }
})

const submitLogout = async () => {
  await logout();
  router.replace({
    name: "interests"
  })
}

</script>

<style scoped>

</style>