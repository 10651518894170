<template>
  <div class=" border-top-wide border-primary d-flex flex-column">
    <div class="page page-center">
      <div class="container-tight py-4">
        <div class="text-center mb-sm-2">
          <a href="#" class="navbar-brand navbar-brand-autodark"><img
              src="../assets/logo.png" height="70" alt="مصلحة"></a>
          <p class="fw-bold fs-2 mb-0"> مصلحة-maslaha</p>
        </div>
        <form class="card card-md" @submit.prevent="submitLogin" autocomplete="off">
          <div class="card-body">
            <h2 class="card-title text-center mb-4">تسجيل الدخول إلى حسابك</h2>
            <div class="mb-3">
              <label class="form-label">عنوان البريد الالكترونى</label>
              <input type="email" class="form-control" placeholder="Enter email" v-model="user.email"
                     :class="errors?' is-invalid':null">
            </div>
            <!-- Password-->
            <div class="mb-2">
              <label class="form-label">
                كلمة المرور
                <span class="form-label-description">
                  <a href="#">لقد نسيت كلمة المرور</a>
                </span>
              </label>
              <div class="input-group input-group-flat">
                <input :type="password" class="form-control" :class="errors?' is-invalid':null" placeholder="Password"
                       autocomplete="off" v-model="user.password">
                <span @click="toggleShowPassword" class="input-group-text">
                  <span class="link-secondary" title="Show password" data-bs-toggle="tooltip"><!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                         stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                         stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="12"
                                                                                                            cy="12"
                                                                                                            r="2"/><path
                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"/></svg>
                  </span>
                </span>
              </div>
            </div>
            <div class="alert alert-danger" v-if="errors">{{ errors }}</div>
            <div class="mb-2">
              <label class="form-check">
                <input type="checkbox" class="form-check-input"/>
                <span class="form-check-label">Remember me on this device</span>
              </label>
            </div>
            <div class="form-footer">
              <button v-if="!loading" type="submit" class="btn btn-primary w-100">تسجيل الدخول</button>
              <button v-else type="button" class="btn btn-primary w-100" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </form>
        <div class="text-center text-muted mt-3">
          ليس لديك حساب؟ <a href="#" tabindex="-1">أفتح حساب الأن</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>


import {useAuth} from "@/services/useAuth";
import router from "@/router";
import {ref} from "vue";

const user = ref({});
const {login, errors, loading} = useAuth();
const password = ref("password");
const toggleShowPassword = () => {
  password.value = "text";
}
const submitLogin = async () => {
  let status = await login(user);
  if (status) {
    await router.push({
      name: 'interests'
    });
  }

}

</script>

<style scoped>

</style>