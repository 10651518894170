<template>
  <div class="wrapper position-relative layout-fluid" v-if="user&&!loading">
    <LayoutHeader/>
    <LayoutNavbar/>
    <router-view></router-view>
    <LayoutFooter/>
  </div>
  <LoginView v-if="!user&&!loading"/>
</template>

<script setup>
import LayoutFooter from "@/layouts/partials/LayoutFooter";
import LayoutHeader from "@/layouts/partials/LayoutHeader";
import LayoutNavbar from "@/layouts/partials/LayoutNavbar";
import {useAuth} from "@/services/useAuth";
import LoginView from "@/views/LoginView";
import {onMounted} from "vue";

// import bootstrap
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import '@tabler/core/dist/js/tabler.min.js';

const {checkAuth, user, loading} = useAuth();

onMounted(async () => {
  await checkAuth();
})

</script>

<style>
@import '@tabler/core/dist/css/tabler.min.css';

</style>

