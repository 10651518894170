import {ref} from "vue";
import {api} from "@/boot/axios";

export const user = ref();
const permissions = ref([])

export function useAuth() {
    const loading = ref(false);
    const errors = ref(null);

    const login = async (u) => {
        let status = false;
        loading.value = true;
        errors.value = null;
        u.value.device_name = "device name";
        await api.post('/admin/login', u.value).then(res => {
            localStorage.setItem('userToken', res?.data?.token);
            user.value = res?.data?.user;
            status = true;
        }).catch(error => {
            errors.value = error.response.data.errors;
        })
        loading.value = false;
        return status;


    }

    const checkAuth = async () => {
        loading.value = true;
        await api.get('/admin/profile').then(res => {
            if (res.data.user) {
                user.value = res.data.user;
                permissions.value = res.data.permissions;
            } else {
                errors.value = {
                    message: res.data.message
                };
            }
            user.value = res.data.user;
        }).catch(() => {
            errors.value = {
                message: "حدث خطأ ما يرجى المحاولة لاحقا"
            }
        })
        loading.value = false;
    }

    const logout = async () => {
        loading.value = true;
        await api.delete("/logout")
            .then(() => {
                user.value = null;
                localStorage.removeItem("userToken");
            });
    }

    const can = (permissionsChecked) => {
        if (!user.value || !permissions.value) {
            return false;
        }

        console.log({permissionsChecked})
        if (!(permissionsChecked instanceof Array)) {
            permissionsChecked = [permissionsChecked];
        }

        return permissions.value.includes(...permissionsChecked)
    }

    const authenticated = () => {
        return !!user.value;
    }

    return {
        loading,
        user,
        permissions,
        errors,

        login,
        checkAuth,
        logout,
        can,
        authenticated,
    }
}