<template>
  <div class="col-md-8 col-lg-6">
    <div class="card" style="height: 100%;">

      <div class="card-header" style="padding-bottom: 5px;padding-top: 5px;">
        <div class="row" style="width: 100%;display: flex; align-items: center;">

          <div class="col">
            <h3 class="card-title"> {{ title }} </h3>
          </div>
          
          <div class="col">
            <select 
              v-model="period" 
              class="form-select"  
              @change="updatePeriodicalGains"
              style="
                width: min-content;
                float: right;
                border: none;
                outline: none;
                box-shadow: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;">

              <option value="year">
                السنة
              </option>
              <option value="month">
                الشهر
              </option>
              <option value="week">
                الأسبوع
              </option>
              <option value="day">
                اليوم
              </option>
            </select>

          </div>
          
        </div>
      </div>

      <div class="card-body">
        <vue-apex-charts ref="chart" height="100%" :options="chartOptions" :series="series" type="bar"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted , ref } from "vue";
import VueApexCharts from 'vue3-apexcharts'
import { useDashboard } from "../../services/useDashboard.js"

const { getPeriodicalGains } = useDashboard();

const chartValues = ref([])
const period = ref("week")
const chart = ref();

const title = computed(()=>{
  if(period.value == "year"){
    return "أرباح السنة الأخير"
  }else if(period.value == "month"){
    return "أرباح الشهر الأخير"
  }else if(period.value == "week"){
    return "أرباح الأسبوع الأخير"
  }else if(period.value == "day"){
    return "أرباح اليوم الأخير"
  }else{
    return "أرباح الأسبوع الأخير"
  }
})

const chartOptions = ref({
  chart: {
    id: 'periodicalGains'
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },
    tooltip: {
      enabled: true,
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      borderRadiusApplication: 'end', // Apply the border radius to the top of the bars only
      borderRadiusWhenStacked: 'all',
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    }
  },
  dataLabels: {
    enabled: true,
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
})

const series = ref([{
  name: 'الأرباح',
  data: chartValues
}])

onMounted(async () => {
  refreshData()
})

const refreshData = async () => {
  updatePeriodicalGains()
}

const updatePeriodicalGains = async () => {

let response = await getPeriodicalGains(period.value)

if(response.status){
  chartValues.value = response.data.totals
  chart.value.updateOptions({
    xaxis: {
      categories: response.data.dates
    }})
}
}

</script>
