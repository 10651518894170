import {reactive, ref} from "vue";
import {api} from "@/boot/axios";

const shoppingCart = ref([]);
export default function useOrders() {

    const loading = reactive({
        saveOrder: false,
    })
    const addToCart = (item) => {
        let found = shoppingCart.value.find(i => i.id === item.id);
        if (found) {
            found.quantity++;
        } else {
            shoppingCart.value.push({
                ...item,
                quantity: 1,
            })
        }

        localStorage.setItem('shoppingCart', JSON.stringify(shoppingCart.value));
    }

    const storeOrder = async (order) => {
        loading.saveOrder = true;
        let createdRecord = false;
        await api.post("/orders", order)
            .then((res) => {
                if (res.data.success) {
                    shoppingCart.value = [];
                    createdRecord = res.data.record;
                    localStorage.removeItem('shoppingCart');
                } else {
                    console.log(res.data.message)
                }
            }).catch(() => {
                console.log("error")
            })
        loading.saveOrder = false;
        return createdRecord;
    }


    return {
        shoppingCart,
        addToCart,
        storeOrder,
        loading,
    }
}