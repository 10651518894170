import {api} from "@/boot/axios";

const getTotals= async ()=>{
    let response = {
        status:false,
        data:{}
    }
    await api.get('dashboard/totals').then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })
    return response
}

const getTopServices= async ()=>{
    let response = {
        status:false,
        data:{}
    }
    await api.get('dashboard/services').then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })
    return response
}

const getRecentCharges= async ()=>{
    let response = {
        status:false,
        data:{}
    }
    await api.get('dashboard/charges').then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })
    return response
}

const getPeriodicalGains= async (period)=>{
    let response = {
        status:false,
        data:{}
    }
    await api.get('dashboard/revenue/'+period).then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })
    return response
}

const getOrdersByEditors= async ()=>{
    let response = {
        status:false,
        data:{}
    }
    await api.get('dashboard/editor-orders').then(res=>{
        response.data = res.data
        response.status = true
        return response
    }).catch(error=>{
        console.log(error);
    })
    return response
}

export function  useDashboard(){
   return {
        getTotals,
        getTopServices,
        getRecentCharges,
        getPeriodicalGains,
        getOrdersByEditors
   }
}